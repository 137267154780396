import { registerResource } from './utils/localization';

export const SpotlightLocale = {
  en: {
    home: {
      title: 'Home',
      subtitle:
        "The Administration Console's Dashboard is InformaCast's homepage, and it contains quick-access buttons for initiating scenarios, accessing sites, users, distributions, etc., visiting your System Health Dashboard, or getting help with InformaCast issues. ",
    },
    users: {
      title: 'Users',
      subtitle:
        'Users are people within InformaCast whose abilities vary depending on their type and permissions.',
    },
    distributionLists: {
      title: 'Distribution Lists',
      subtitle:
        'Distribution lists allow you to group recipients to send them notifications or include them in roll call lists.',
    },
    desktopDevices: {
      title: 'Desktop Devices',
      subtitle:
        'Desktop devices are Windows or Mac desktops, tablets, etc. that can receive notifications through the InformaCast app.',
    },
    deviceGroups: {
      title: 'Device Groups',
      subtitle: 'Device groups allow you to group on-premises devices to send them notifications.',
    },
    aois: {
      title: 'Areas of Interest',
      subtitle:
        'Areas of interest are geographically defined circles that encompass locations, such as a college campus or a state park.',
    },
    trackingEvents: {
      title: 'Tracking Events',
      subtitle:
        "Tracking events record users' locations through the InformaCast app on their mobile devices and display them in a map to aid you in assisting them to safety.",
    },
    conferenceCalls: {
      title: 'Conference Calls',
      subtitle:
        'Conference calls allow you send a notification or dial a specific number and begin a multi-person call with specific users.',
    },
    webex: {
      title: 'Cisco Webex',
      subtitle:
        'Webex spaces allow you to send a notification to an existing Webex space or create a new one with new members.',
    },
    twitter: {
      title: 'Twitter',
      subtitle:
        "Twitter allows you to post a notification's text contents (and optionally its image) as a tweet sent from an account you administer.",
    },
    outboundCap: {
      title: 'Outbound CAP',
      subtitle:
        'Outbound CAP (Common Alerting Protocol) allows you to post a notification’s text contents to your endpoints that support the consumption of CAP messages, e.g. digital signs.',
    },
    outboundRss: {
      title: 'Outbound RSS',
      subtitle:
        'Outbound RSS (Really Simple Syndication) allows you to post a notification’s text contents into an RSS feed. ',
    },
    msteams: {
      title: 'Microsoft Teams',
      subtitle: 'Microsoft Teams allows you to send notifications to Microsoft Teams workspaces.',
    },
    webexCalling: {
      title: 'Webex Calling',
      subtitle:
        'Integrate with Webex Calling, and view the status of Webex Calling Devices, Import Webex Calling Workspaces, and verify Webex Calling integration scenario is available.',
    },
    speakers: {
      title: 'IP Speakers',
      subtitle:
        "IP speakers play a notification's audio content and may display its text contents, if supported.",
    },
    desktopNotifiers: {
      title: 'Desktop Notifiers',
      subtitle:
        "InformaCast Desktop Notifier instances' Notification windows play a notification's audio content and display its text contents. They will also display an image or confirmation request, if configured.",
    },
    lpiConfiguration: {
      title: 'Configuration',
      subtitle:
        "The Legacy Paging Interface's configuration allows you to modify the parameters that affect its behavior, such as the amount of time it waits to collect incoming activation requests or the six-digit hexadecimal number that it uses for generating unique MAC addresses for its paging zones",
    },
    lpiServerGroups: {
      title: 'SIP Server Groups',
      subtitle:
        "The Legacy Paging Interface's SIP server groups allow you to make a call to a SIP server in order to send a notification to a paging device.",
    },
    lpiPagingDevices: {
      title: 'Paging Devices',
      subtitle:
        "The Legacy Paging Interface's paging devices are physical units that receive notification's audio content via one or several groups of loudspeakers, i.e. paging zones.",
    },
    lpiCurrentActivities: {
      title: 'Current Activities',
      subtitle:
        "The Legacy Paging Interface's current activities are a record of notifications' activities, e.g. start time, the sender’s IP address, the multicast IP address and port of InformaCast's audio stream, etc.",
    },
    pagingGateways: {
      title: 'Paging Gateways',
      subtitle:
        "The Paging Gateway allows you to send a notification's audio content across a network that does not/cannot support multicast traffic.",
    },
    m2mContactClosures: {
      title: 'Contact Closures',
      subtitle:
        "Machine-to-Machine (M2M) allows you to send a notification when a contact closure is activated or activate a contact closure by sending a notification, e.g. ringing a doorbell sends the Answer the Door notification or sending the Someone's Here notification turns on the outside spotlight.",
    },
    m2mSchedules: {
      title: 'Schedules',
      subtitle:
        'Machine-to-Machine (M2M) schedules allow you to set times when a contact closure is active. Active contact closures will be affected by/send notifications; inactive contact closures will not.',
    },
    quickUrl: {
      title: 'Quick URL',
      subtitle: "Quick URL allows you to send a notification's text contents to a designated URL.",
    },
    apiDevices: {
      title: 'API Devices',
      subtitle:
        "API devices are external devices, mapped to a location within a site, that can make API calls to InformaCast and cause events to occur, such as asking InformaCast to initiate a scenario, which then includes the API device's location in the scenario's details.",
    },
    notificationHistory: {
      title: 'Notification History',
      subtitle:
        "Notification history allows you to view the details of the notifications you've sent to see the complete picture of your notification's reach.",
    },
    messageTemplates: {
      title: 'Message Templates',
      subtitle:
        'Message templates are the building blocks of the notifications you will send. When configured, they can contain text, audio, an image, a confirmation response, your intended recipients, etc.',
    },
    confirmationRequests: {
      title: 'Confirmation Requests',
      subtitle:
        'Confirmation requests allow you to configure a response for your recipients to send back to you after receiving a notification.',
    },
    siteRoles: {
      title: 'Site Roles',
      subtitle:
        "Site roles describe the audiences of standard or integration scenarios' notifications, and they are site-dependent, e.g. the IT Admin role for the West Campus is not the same person as the IT Admin role for the East Campus, and IT Admin East will not receive a scenario's notifications when the location is the West Campus.",
    },
    sites: {
      title: 'Sites',
      subtitle:
        "Sites are a space of ground within your organization tied to a collection of site roles whose associated recipients may receive notifications if the site to which they're assigned is chosen when initiating a standard or integration scenario.",
    },
    scenarios: {
      title: 'Scenarios',
      subtitle:
        'Scenarios send a series of notifications to separate audiences in response to an emergency or a set of circumstances. Depending on their type, you can click or tap a button to send them, press a badge button, dial 911, or activate an API device.',
    },
    incidentPlans: {
      title: 'Incident Plans',
      subtitle:
        'Incident plans are agendas to follow when there is an ongoing incident in your organization.',
    },
    incidents: {
      title: 'Incidents',
      subtitle:
        'Incidents are an event, critical or otherwise, that require a centralized management strategy. During an incident, use the Administration Console as your center of operations: see where an incident is occurring, keep track of the notifications being sent, conduct roll call, access important incident resources, etc.',
    },
    apiConnectors: {
      title: 'API Connectors',
      subtitle:
        'API connectors allow you to send InformaCast data to external applications through their public APIs. Use API connectors to configure your own custom events/actions to occur based on scenario initiations.',
    },
    ipCameras: {
      title: 'IP Cameras',
      subtitle: 'todo',
    },
    verkada: {
      title: 'Verkada',
      subtitle:
        'The Verkada IP Camera Integration allows you to import Verkada IP Cameras into InformaCast.',
    },
    trackingEventNotifications: {
      title: 'Tracking Event Notifications',
      subtitle:
        'Tracking event notifications allow you to tie a message template to a geographical location and send a notification when a user initiates tracking near the location.',
    },
    notificationProfiles: {
      title: 'Notification Profiles',
      subtitle:
        'Notification profiles allow you to create a set of preferences that control the delivery, content, and layout of future notifications, and assign them to message templates.',
    },
    followUpMessageTemplate: {
      title: 'Follow-up Message Template',
      subtitle:
        "The Follow-up message template allows you to prompt a notification's receipients for a further response when they haven't performed the desired action.",
    },
    dialingConfiguration: {
      title: 'Dialing Configurations',
      subtitle:
        'DialCast dialing configurations allow you to preset a message template and its recipients, and then send a notification when you dial a certain number.',
    },
    phoneExceptions: {
      title: 'Phone Exceptions',
      subtitle:
        "DialCast phone exceptions are override settings for your dialing configurations and other parameters you've set for the Inbound Call Voice menu.",
    },
    callawareConfiguration: {
      title: 'Configuration',
      subtitle:
        "CallAware's configuration allows you to establish communication between it and Cisco Unified CM as well as set the amount of time for which InformaCast will retain call history.",
    },
    callawareCUCM: {
      title: 'Cisco Unified CM Clusters',
      subtitle:
        "CallAware's Cisco Unified CM cluster configuration determines the connection type between CallAware and Cisco Unified CM. InformaCast uses this connection to send a notification when one of your users dials 911 (or another monitored number).",
    },
    callawareCallRedirects: {
      title: 'Call Redirects',
      subtitle:
        "CallAware's call redirects contain routing instructions for calls observed on a particular route point DN, set whether the call should be recorded, and specify the type of notification that should be sent when an observed call occurs.",
    },
    callawareRoutingRequests: {
      title: 'Routing Requests',
      subtitle:
        "CallAware's routing requests contain instructions for routing the calls observed on a transformed called DN, set whether the call should be recorded, and specify the type of notification that should be sent when an observed call occurs.",
    },
    callawareCallHistory: {
      title: 'Call History',
      subtitle:
        "CallAware's call history allows you to view the details of all calls that have been routed by CallAware.",
    },
    callawareDiagnostics: {
      title: 'Diagnostics',
      subtitle:
        "CallAware's diagnostics allow you to view the details of your connection with Cisco Unified CM, your CTI route points, and CTI ports.",
    },
    scheduledNotifications: {
      title: 'Scheduled Notifications',
      subtitle:
        'Scheduled notifications allow you to arrange for a notification to be sent at a prearranged time in the future, either once or on a recurring basis.',
    },
    inboundCap: {
      title: 'Inbound CAP',
      subtitle:
        'Inbound CAP (Common Alerting Protocol) allows you to send notifications when certain CAP alerts are sent by various Singlewire-managed sources. ',
    },
    email: {
      title: 'Email',
      subtitle:
        'Inbound Email allows you to create collections of rules and apply them to a monitored email address. When that email address receives an email that contains data matching a rule set, the corresponding notification will be sent.',
    },
    inboundRss: {
      title: 'Inbound RSS',
      subtitle:
        'Inbound RSS feeds allow you to link an RSS feed to a specific message template and send notifications whenever new feed information becomes available.',
    },
    ipawsAlerts: {
      title: 'Alerts',
      subtitle:
        'Integrated Public Alert and Warning System (IPAWS) alerts are message formatted in Common Alerting Protocol (CAP) that FEMA has authorized you to send to certain areas.',
    },
    ipawsCustomAreas: {
      title: 'Custom Areas',
      subtitle:
        'Integrated Public Alert and Warning System (IPAWS) custom areas allow you to specify geographical locations in which public safety endpoints, e.g. cell phones, television stations, etc., will receive and broadcast IPAWS alerts.',
    },
    bellScheduleOverview: {
      title: 'Overview',
      subtitle:
        'The Bell Schedules Overview displays your ring lists and bell schedules in a calendar format.',
    },
    ringLists: {
      title: 'Ring Lists',
      subtitle:
        'Ring lists specify the ring tones to be played at specific times of day and the recipients that will play each tone.',
    },
    bellSchedules: {
      title: 'Schedules',
      subtitle: 'Bell schedules specify the ring lists to be used on specific calendar dates.',
    },
    bellScheduleExceptions: {
      title: 'Exceptions',
      subtitle:
        'Bell schedule exceptions replace the regularly scheduled ring lists for a certain bell schedule with new ring lists for the date(s) selected.',
    },
    nightBellConfiguration: {
      title: 'Configuration',
      subtitle:
        "Night Bell's configuration allows you to establish communication between itself and Cisco Unified CM as well as set the amount of time for which a notification should persist if a call isn't answered.",
    },
    nightBellCUCM: {
      title: 'Cisco Unified CM Clusters',
      subtitle:
        "Night Bell's Cisco Unified CM cluster configuration sets the connection between itself and Cisco Unified CM. Night Bell uses this connection to send a notification when a call comes in on a monitored DN.",
    },
    nightBellTriggers: {
      title: 'Triggers',
      subtitle:
        "Night Bell's triggers contain instructions for routing the calls observed on a monitored CTI port and DN, and they specify the type of notification that should be sent when a call is observed.",
    },
    nightBellDiagnostics: {
      title: 'Diagnostics',
      subtitle:
        "Night Bell's diagnostics allow you to view the details of its connection with Cisco Unified CM and your CTI ports' configurations.",
    },
    fusionConfiguration: {
      title: 'Server Configuration',
      subtitle:
        'The Configuration Details page is a diagnostic tool for the overall configuration of your on-premises server.',
    },
    systemHealth: {
      title: 'System Health',
      subtitle:
        "The System Health Dashboard allows you to view the status of InformaCast's internal metrics to aid you in troubleshooting system issues before they impact your ability to send notifications. You can also set alarms based on changes in system health.",
    },
    bulkUploads: {
      title: 'Bulk Uploads',
      subtitle:
        "Bulk uploads allow you to add multiple resource records to InformaCast through downloadable templates, such as uploading all of your device groups' data at once.",
    },
    schoolInformation: {
      title: 'School Information',
      subtitle:
        "School information imports allow you to upload a school's day-to-day operations data through a connection to a Student Information System (SIS).",
    },
    userLoaders: {
      title: 'User Loaders',
      subtitle:
        "User loaders allow you to import your users' information all at once, either through a CSV file, an LDAP connection, or a Windows Azure Active Directory connection.",
    },
    selfService: {
      title: 'Self-registration',
      subtitle:
        "Self Service allows you to manage your notifications and the manner in which they're delivered to you without the assistance of your InformaCast administrator.",
    },
    securityGroups: {
      title: 'Security Groups',
      subtitle:
        'Security groups are a set of users or service accounts who share the same permissions, such as creating message templates or sending notifications to specific distribution lists.',
    },
    applications: {
      title: 'Applications',
      subtitle:
        'Applications allow third-party programs to access InformaCast’s data via OAuth 2.0 authorization, and use it to react to outside events or initiate InformaCast actions.',
    },
    installedApplications: {
      title: 'Installed Applications',
      subtitle: '',
    },
    publicApplications: {
      title: 'Public Applications',
      subtitle: '',
    },
    serviceAccounts: {
      title: 'Service Accounts',
      subtitle:
        'Service accounts allow external programs to request and receive information from InformaCast, such as requesting a certain notification be sent.',
    },
    kontakt: {
      title: 'Kontakt.io',
      subtitle:
        'A Kontakt.io integration allows you to establish a connection between InformaCast and Kontakt.io, import Kontakt.io campuses as InformaCast sites, and allow people in your organization to press their badge buttons and send a scenario.',
    },
    msteamsSettings: {
      title: 'Microsoft Teams',
      subtitle:
        "The Microsoft Teams Configuration page is a diagnostic tool for the overall configuration of InformaCast's connection to Microsoft Teams, such as the health of the connection, the users they have in common, and certain notification settings.",
    },
    zoom: {
      title: 'Zoom',
      subtitle:
        'A Zoom integration allows you to establish a connection between InformaCast and Zoom, import Zoom sites, and initiate a scenario when someone in your organization dials 911.',
    },
    triggers: {
      title: 'Triggers',
      subtitle:
        'Triggers allow you to make an HTTP POST or send a message to an Amazon SQS queue and notify an external system when changes are made to a resource within InformaCast.',
    },
    servers: {
      title: 'Servers',
      subtitle:
        'Servers are the on-premises component of InformaCast, and they allow you to send notifications to on-premises devices, such as IP speakers. Their management includes restarting services, collecting logs, syncing resources, backups and restores, etc.',
    },
    cucmClusters: {
      title: 'Clusters',
      subtitle:
        'Cisco Unified CM clusters allow you send notifications to Cisco IP phones through a configured connection between InformaCast and Cisco Unified Communications Manager.',
    },
    ctiCertificates: {
      title: 'CTI Certificates',
      subtitle:
        'CTI certificates use Computer Telephony Integration over Transport Layer Security and Secure Real-Time Transport Protocol to secure the communication between InformaCast and Cisco Unified Communications Manager.',
    },
    externalCallControl: {
      title: 'External Call Control',
      subtitle:
        'External Call Control (ECC) allows you to configure InformaCast as a web service that provides ECC to Cisco Unified CM, allowing CallAware to send a notification when one of your users dials 911.',
    },
    phoneRefreshOverview: {
      title: 'Phone Refresh Overview',
      subtitle:
        "The Phone Refresh Overview page allows you to view information on the scheduled jobs for building and refreshing InformaCast's cache of Cisco IP phones for Unified CM.",
    },
    phoneParameters: {
      title: 'Phone Parameters',
      subtitle:
        'Phone parameters control InformaCast’s behavior in regards to Cisco IP phones for Unified CM, such as gathering their information, activating them, and associating them with users.',
    },
    multicastStreams: {
      title: 'Multicast Streams',
      subtitle:
        'Multicast streams send notification audio to devices that continuously listen for it at a multicast IP address and port.',
    },
    clearDevices: {
      title: 'Clear Devices',
      subtitle:
        'Clear Devices allows you to return the Services windows of Cisco IP phones for Unified CM to their default state without disrupting any active phone calls, and clear InformaCast Desktop Notifier Notification windows.',
    },
    sipAccess: {
      title: 'Access',
      subtitle:
        'Session Initiation Protocol (SIP) access allows you to set your preferences for allowing or denying incoming SIP calls.',
    },
    sipAccounts: {
      title: 'Accounts',
      subtitle:
        'Session Initiation Protocol (SIP) accounts allow you to establish a SIP line connection between InformaCast and your telephony provider.',
    },
    callSecurity: {
      title: 'Call Security',
      subtitle:
        'Call security allows you to set your security level for the content of Session Initiation Protocol (SIP) calls made and received by InformaCast.',
    },
    sipCertificates: {
      title: 'SIP Certificates',
      subtitle:
        'Session Initiation Protocol (SIP) certificates are the digital certificates that InformaCast uses in its secure communication with SIP endpoints.',
    },
    sipRestart: {
      title: 'SIP Restart',
      subtitle:
        "Changes to the Session Initiation Protocol (SIP) stack or SIP certificates require you to restart InformaCast's SIP service before those changes can take effect.",
    },
    sipRegistrations: {
      title: 'SIP Registrations',
      subtitle: '',
    },
    sipServerGroups: {
      title: 'SIP Server Groups',
      subtitle:
        'Session Initiation Protocol (SIP) servers are used by LPI to facilitate failover to another SIP server if the main server is unavailable.',
    },
    sipSpeakerParameters: {
      title: 'SIP Speaker Parameters',
      subtitle:
        "Session Initiation Protocol (SIP) speaker parameters control the location of your telephony provider and your preferences for how often SIP speakers check providers' call availability, their registration interval, the length of time they should ring before ending a call, etc.",
    },
    sipStack: {
      title: 'Stack',
      subtitle:
        'The Session Initiation Protocol (SIP) stack provides InformaCast with fundamental low-level SIP functionality such as transaction handling, dialogs, utilities for SIP headers, maintenance of SIP timers, etc.',
    },
    userCredentials: {
      title: 'User Credentials',
      subtitle:
        'User credentials allow you to configure digest authentication for each Session Initiation Protocol (SIP) realm where you expect InformaCast to be challenged by peers when registering or terminating a call.',
    },
    dnsRecords: {
      title: 'DNS SRV Records',
      subtitle:
        'The List Domain Name Service (DNS) Service (SRV) Records page displays the DNS SRV records InformaCast is using to locate Session Initiation Protocol (SIP) servers, which it uses during registration with SIP accounts, calls with LPI SIP server groups, and registration with telephony providers for full-duplex intercom calls.',
    },
    dialToIntercom: {
      title: 'Dial To Intercom',
      subtitle:
        'Dial-to-intercom calls allow you to dial the extension of a Cisco IP phone for Unified CM and be immediately connected with full-duplex intercom functionality. They do not require any action on the part of the receiving IP phone.',
    },
    licenses: {
      title: 'Licenses',
      subtitle:
        "Licenses control the number of individual and anonymous users you're allowed to add to InformaCast and the number of on-premises devices you can add to device groups or activate through activation groups.",
    },
    encryptionKey: {
      title: 'Encryption Key',
      subtitle:
        'The encryption key is a public and private key pair used by your on-premises servers to encrypt the communication between themselves and the cloud when syncing sensitive Cisco Unified CM data. Occasionally, this key needs to be rotated.',
    },
    appearance: {
      title: 'Appearance',
      subtitle:
        'Appearance settings allow you to customize your Administration Console and Self Service user interface pages with your preferred colors and logo.',
    },
    calling: {
      title: 'Calling',
      subtitle:
        'Calling settings allow you to set certain preferences for your phone call notifications or conference calls, such as your assigned telephone number, introductory text, and/or the voice speaking introductory text. You can also disable phone call notifications and conference calls altogether.',
    },
    idns: {
      title: 'Desktop Notifiers',
      subtitle:
        'Desktop Notifier settings allow you to customize the menu options displayed for your users and/or provide a custom logo, which displays in the Notification window.',
    },
    dnd: {
      title: 'Do Not Disturb',
      subtitle:
        'Do Not Disturb settings allow you to control whether users can set their own Do Not Disturb windows through Self Service and/or the InformaCast app on their mobile devices.',
    },
    distributedActivation: {
      title: 'Distributed Activation',
      subtitle:
        'Distributed activation allows you to install and configure multiple InformaCast on-premises servers to activate devices faster by distributing loads.',
    },
    domains: {
      title: 'Domains',
      subtitle:
        'Domains allow you to view resources through a hierarchical architecture, and grant permissions to those resources at each level.',
    },
    domainsConfig: {
      title: 'Domains Settings',
      subtitle:
        'Domains settings allow you to enable or disable the domains hierarchical structure for your organization.',
    },
    fusionFailover: {
      title: 'Failover Configuration',
      subtitle: '',
    },
    emailSettings: {
      title: 'Email',
      subtitle:
        'Email settings allow you to set certain preferences for your email notifications, such as their layout, the name and address from which they appear to originate, etc. You can also disable email notifications altogether.',
    },
    support: {
      title: 'In-app Support',
      subtitle:
        "In-app support settings allow you to provide your own help documentation for your Self Service users and/or display your organization's name and contact information within the InformaCast app for users in need of assistance.",
    },
    informacastApps: {
      title: 'InformaCast Apps',
      subtitle:
        "InformaCast app settings allow you to enable or disable push notifications to your users' mobile or desktop devices, e.g. Android, iOS, Windows, and/or Mac.",
    },
    sms: {
      title: 'SMS',
      subtitle:
        "SMS settings allow you to set certain preferences for your SMS notifications, such as whether notifications' text contents are concatenated, the introductory text displayed before SMS notifications, whether you use alphanumeric sender IDs, etc. You can also disable SMS notifications altogether.",
    },
    timeZone: {
      title: 'Time Zone and Locale',
      subtitle:
        'Time zone and locale settings allow you to set your preferences for the time zone used in variables and its format, e.g. January 27, 2022 at 2:17:00 PM versus 27 January 2022 at 14:17:00.',
    },
    ipaws: {
      title: 'IPAWS',
      subtitle:
        "Integrated Public Alert and Warning System (IPAWS) settings allow you to enter your Collaborative Operating Group's (COG) information, which will allow InformaCast to send IPAWS alerts.",
    },
    tts: {
      title: 'Text-to-Speech',
      subtitle:
        'Text-to-speech settings allow you to set certain preferences for your text-to-speech audio content, such as the default voice and language and a dictionary of word pronunciations.',
    },
    broadcastParameters: {
      title: 'Broadcast Parameters',
      subtitle:
        "Broadcast parameters settings allow you to set your preferences for the phone vibration and backlight display period for Cisco IP phones as well as InformaCast's handling of the audio parameters for those phones, IP speakers, InformaCast Desktop Notifier instances.",
    },
    certificates: {
      title: 'Certificates',
      subtitle:
        "Certificate settings allow you to control the security of InformaCast's connections to external hosts.",
    },
    networkParameters: {
      title: 'Network Parameters',
      subtitle:
        'Service Location Protocol (SLP) settings allow you to control whether InformaCast responds to SLP multicast discovery requests for configuration files.',
    },
    slpParameters: {
      title: 'SLP Parameters',
      subtitle:
        'Service Location Protocol (SLP) settings allow you to control whether InformaCast responds to SLP multicast discovery requests for configuration files.',
    },
    ipSpeakerSettings: {
      title: 'IP Speakers',
      subtitle:
        'IP speaker settings allow you to set certain preferences for IP speakers, InformaCast Desktop Notifier instances, and Legacy Paging Interface (LPI) paging zones, such as whether they are automatically added to InformaCast and their initial volume.',
    },
    voiceMenus: {
      title: 'Voice Menus',
      subtitle:
        "Voice menu settings allow you to set certain preferences for InformaCast's Interactive Voice Response (IVR) menus.",
    },
    userReports: {
      title: 'User Reports',
      subtitle:
        "The User report provides you information on your individual users' attributes, such as their names, email addresses, distribution list subscriptions, device types and their details, etc.",
    },
    anonUserReports: {
      title: 'Anonymous User Reports',
      subtitle:
        'The Anonymous User report provides you with information on your anonymous users, such as their registered device numbers, distribution list memberships, subscribe tags, etc.',
    },
    notificationHistoryReports: {
      title: 'Notification History Reports',
      subtitle:
        "The Notification History report provides you with details of the notifications you've sent within the last 12 months, such as their send dates, the users who sent them, the numbers of recipients, etc.",
    },
    confirmationReports: {
      title: 'Confirmation Reports',
      subtitle:
        "The Confirmation report provides you with details of users' confirmation responses to notifications sent within the last 12 months, such as the users who responded, the time they responded, the confirmation request they were sent, etc.",
    },
    changeHistoryReports: {
      title: 'Change History Reports',
      subtitle:
        "The Change History report provides you with details on the changes that have been made to certain resources over the course of time, such as the creation of a user, a bell schedule changing names, a user's device being updated or deleted, etc.",
    },
  },
};

export default SpotlightLocale;
export const spotlight_t = registerResource<typeof SpotlightLocale.en>(
  'spotlight',
  SpotlightLocale
);
