import isNil from 'lodash/isNil';
import * as React from 'react';
import { TextField as RegistryTextField, TextFieldProps } from 'singlewire-components';
import { shared_t } from '../SharedLocale';

interface ExternalProps {
  /** Whether or not we show an info icon when text contains template variables */
  shouldShowTemplateInfo?: boolean;
}

const hasVariables = (text: string) => {
  if (!isNil(text) && typeof text === 'string') {
    let delimStart = text.indexOf('{{');
    let delimEnd = text.indexOf('}}');
    if (delimStart === -1 || delimEnd === -1) {
      delimStart = text.indexOf('${');
      delimEnd = text.indexOf('}');
    }
    return delimStart > -1 && delimEnd > -1 && delimEnd > delimStart;
  }
  return false;
};

export const TextField = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    ExternalProps &
    TextFieldProps
) => {
  const { ref, shouldShowTemplateInfo, ...rest } = props;
  const helpTextId = 'field-help-text';

  return (
    <RegistryTextField
      {...rest}
      color={'primary' as any}
      error={props.error}
      helperText={rest.helperText}
      showVariablesIcon={shouldShowTemplateInfo && hasVariables(props?.value as string)}
      variablesTooltip={shared_t(['characterCounter', 'templateVarWarning'], {
        fieldDescription: shared_t(['characterCounter', 'defaultFieldDesc']),
        maxCount: rest.maxLength,
      })}
      helperTextId={helpTextId}
      helperTextClassName="field-errors-container"
      aria-describedby={helpTextId}
    />
  );
};
