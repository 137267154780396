import { registerResource } from '../../core/utils/localization';

export const LayoutLocale = {
  en: {
    cancelOrExpire: {
      abort: {
        subtitle:
          'You have chosen to cancel this notification, which will attempt to stop any queued and/or active notifications, decline further confirmation responses from users, and prevent escalation rules from triggering other notifications. Please confirm your cancellation, and optionally include a comment on the reason for the cancellation.',
        title: 'Cancel Notification',
      },
      expire: {
        subtitle:
          'You have chosen to expire this notification, which will decline further confirmation responses from users and prevent escalation rules from triggering other notifications.  Please confirm the expiration, and optionally include a comment on the reason for the expiration.',
        title: 'Expire Notification',
      },
      comment: 'Comment',
    },
    footer: {
      copyright:
        "© 2003 Singlewire Software, LLC. All rights reserved. This application incorporates third-party software under each package's own license terms. No other part of the software or material contained in it may be transmitted, used, reproduced, or disclosed outside of the receiving party without the express written consent of Singlewire Software, LLC. Use of this software is subject to the terms and conditions of the applicable Singlewire Software license for the software.",
      home: 'Home',
      about: 'About',
      support: 'Support',
    },
    domains: {
      noDomains: 'No domains',
      noDomainsFound: 'No domains found',
      domainSelectWidget: {
        selected: 'Current Domain',
        available: 'Available Domains',
      },
      label: {
        close: 'Close Domains Dropdown Menu',
        domainSelect: 'Domains Dropdown Menu',
        manage: 'Manage',
        currentDomain: 'Current Domain',
      },
    },
    facilities: {
      noFacilities: 'No facilities',
      noFacilitiesFound: 'No facilities found',
      facilitySelectWidget: {
        selected: 'Current Facility',
        available: 'Available Facilities',
      },
      label: {
        close: 'Close Facilities Dropdown Menu',
        facilitySelect: 'Facilities Dropdown Menu',
        manage: 'Manage',
        currentFacility: 'Current Facility',
      },
    },
    fusion: {
      serverSelectWidget: {
        onPremisesServers: 'On-premises Servers',
        selected: 'Selected On-premises Server',
        available: 'Available On-premises Servers',
      },
      label: {
        close: 'Close On-premises Servers Dropdown Menu',
        serverSelect: 'On-premises Servers Dropdown Menu',
        noDescription: 'No Description',
        manage: 'Manage',
        manageServers: 'Manage Servers',
        activeFusionServer: '* Denotes Server-specific Configurations',
      },
    },
    header: {
      informacastDashboard: 'Singlewire InformaCast - Open Dashboard',
      accessibilityTools: 'Accessibility Tools',
      changePassword: 'Change Password',
      cancel: 'Cancel Notifications',
      userGuide: 'User Guide',
      openApiExplorer: 'API Documentation',
      supportCommunity: 'Support Community',
      view: 'View Notifications',
      profile: 'Profile',
      quickSend: 'Quick Send',
      resourceCenter: 'Resource Center',
      liveCall: 'CallAware Live',
      openLeft: 'Open Main Menu',
      closeLeft: 'Close Main Menu',
      help: 'Help',
      more: 'More',
      manageAccount: 'Manage Account',
      logout: 'Log Out',
      quickCancel: 'Quick Cancel More Actions',
      quickSendMenu: 'Quick Send Menu',
      activeNotificationsMenu: 'Active Notifications Menu',
      skipToContent: 'Skip to content',
      skipToNavigation: 'Skip to navigation',
    },
    modals: {
      errorTitle: 'Alert',
      errorMessage:
        'An unknown error occurred. Please try again. If problems continue, contact your system administrator.',
    },
    sideBar: {
      profile: 'Profile',
      logout: 'Log Out',
      search: {
        label: 'Search Navigation',
        clear: 'Clear Search Field',
        placeholder: 'e.g. Users',
        noResults: 'No Results Found',
      },
    },
    warningBanner: {
      labels: {
        warning: 'Warning',
        caution: 'Caution',
      },
      icop: {
        licenses: {
          contactSupport: 'contact Singlewire Support',
          lockUsers: 'lock users',
          violationAdmin: "Your organization's license is in violation: ",
          violationNoAdmin:
            "The number of active devices exceeds your organization's licensed amount. Please contact your system administrator.",
          violationConsequences:
            'You will not be allowed to add new or edit existing device groups or distribution lists while your license is in violation.',
          overLimit: {
            phones:
              'The number of Cisco IP phones for Unified CM allocated by rules exceeds the maximum licensed amount. Please create a more restrictive rule or contact support.',
            polyPhones:
              'The number of Poly phones allocated by rules exceeds the maximum licensed amount. Please create a more restrictive rule or contact support.',
            speakers:
              'The number of IP speakers allocated by rules exceeds the maximum licensed amount. Please create a more restrictive rule or contact support.',
            idns: 'The number of InformaCast Desktop Notifiers allocated by rules exceeds the maximum licensed amount. Please create a more restrictive rule or contact support.',
            users:
              "The number of users you have exceeds the maximum licensed amount. Please<0>lock users</0> to return to your contracted subscription size or <1>contact Singlewire Support</1>. <p><br>If you're using a <2>cloud user loader</2>, you can edit your group licensing and return your user counts to compliance.</p>",
          },
        },
      },
      desktops: {
        overLimit__HTML__:
          'The number of desktops you have exceeds the maximum licensed amount. Please delete excess desktops to conform to your license or contact <a class="c-amber" target="_blank" href="https://www.singlewire.com/contact-us">Singlewire Sales</a> to purchase a license that meets your needs.',
      },
      expiration: {
        warning:
          'Your provider will expire on <0></0>. Please email Singlewire Sales at <1>renewals@singlewire.com</1> to renew.',
        gracePeriod:
          'Your provider expired on <0></0>, you will no longer have access to Informacast on <1></1>. Please email Singlewire Sales at <2>renewals@singlewire.com</2> to renew.',
      },
      misMatchedServerWarning:
        'You have one or more InformaCast on-premises servers running different versions, which is unsupported. Please <0>upgrade</0> your on-premises server(s) to all use the same version. If you need assistance, please <1>open a support case</1>.',
      fusionServerUpgradeAvailable: {
        bulkSpeakerManagement:
          'Improved IP speaker management is available, but only for the latest versions of InformaCast. You have one or more on-premises server(s) behind the required version. Until you <0>upgrade</0> your on-premises server(s) to all use the same, new version, editing IP speakers or their related settings has been disabled. If you need assistance, please <0>open a support case</0>.',
        cloudDialcastManagement:
          'New DialCast features are available, but only for the latest versions of InformaCast. You have one or more on-premises server(s) behind the required version. Until you <0>upgrade</0> your on-premises server(s) to all use the same, new version, editing dialing configurations or their related settings has been disabled. If you need assistance, please <0>open a support case</0>.',
        oldWarning:
          'You have one or more InformaCast on-premises servers that are behind the current version. Please upgrade your on-premises server(s) through Webmin. If you need assistance, please <0>open a support case</0>.',
        warning:
          'You have one or more InformaCast on-premises servers that are behind the current version. Please <0>upgrade</0> your on-premises server(s). If you need assistance, please <1>open a support case</1>.',
        oldWarning__HTML__:
          '<div style="text-align: center;">You have one or more InformaCast on-premises servers that are behind the current version. Please upgrade your on-premises server(s) through Webmin. If you need assistance, please <a class="c-white-underlined" target="_blank" href="https://www.singlewire.com/support-tools">open a support case</a>.</div>',
        warning__HTML__:
          '<div style="text-align: center;">You have one or more InformaCast on-premises servers that are behind the current version. Please <a class="c-white-underlined" href="#/fusion-servers">upgrade</a> your on-premises server(s). If you need assistance, please <a class="c-white-underlined" target="_blank" href="https://www.singlewire.com/support-tools">open a support case</a>.</div>',
      },
      callAware: {
        callActiveMessage:
          'There are live CallAware Calls being recorded on the following InformaCast servers: ',
      },
      incidents: {
        ongoingIncidentMessage: 'You have an ongoing incident. Click <0>here</0> to manage it.',
        ongoingIncidentsMessage: 'You have ongoing incidents. Click <0>here</0> to manage them.',
      },
      panicButton: {
        id: 'Panic Button',
        header: 'Panic Button Pressed',
        viewLocation: 'View Location',
      },
      trackingEvent: {
        eventsMessage: 'You have ongoing tracking events. Click <0>here</0> to manage them.',
        eventMessage_one:
          'You have an ongoing tracking event with 1 user. Click <0>here</0> to manage it.',
        eventMessage_other:
          'You have an ongoing tracking event with {{userCount}} users. Click <0>here</0> to manage it.',
      },
      alarms: {
        fusionSyncState: {
          title: 'On-premises Server Sync State Issue',
          message:
            'InformaCast server(s) have sync errors. Servers may not be able to send notifications. Visit your <0>System Health Dashboard</0> for more information.',
        },
        redAlarm: 'Red Server Alarm Active',
        redAlarmMessage:
          "InformaCast server(s) are in a red state. Servers in a red state may not be able to send notifications. Switch your selected server or check InformaCast's system health for further error information.",
        visitSystemHealth: 'Visit System Health Dashboard',
        switchServers: 'Switch Servers',
        yellowAlarm: 'Yellow Server Alarm Active',
        yellowAlarmMessage:
          "InformaCast server(s) are in a yellow state. Notifications will still be sent, but the system is unhealthy. Switch your selected server or check InformaCast's system health for further error information.",
        speakersUnregisteredAlarm: 'Speaker Unregistered Alarm Active',
        speakerAlarmMessage:
          "InformaCast server(s) have detected that their number of registered speakers is below your organization's set amount. Visit your <0>System Health Dashboard</0> for more information.",
        licenseAlarm: 'License Alarm Active',
        licenseAlarmMessage:
          "Your instance of InformaCast is in violation of your organization's license. Visit your <0>System Health Dashboard</0> for more information.",
        sisLoaderAlarm: 'School Information Import Alarm Active',
        sisLoaderAlarmMessage:
          'InformaCast has detected an issue with your school information import. Visit your <0>System Health Dashboard</0> for more information.',
        tokenExpirationAlarm: 'Credential Expiration Alarm Active',
        tokenExpirationAlarmMessage:
          'InformaCast has detected service account tokens or application secrets close to expiration. Visit your <0>System Health Dashboard</0> for more information.',
        scenarioDeviceResolutionFailedAlarm: 'API Device Alarm Active',
        scenarioDeviceResolutionFailedAlarmMessage:
          'InformaCast has detected issues with an API device. Visit your <0>System Health Dashboard</0> for more information. ',
        potentialSpeakerConfigurationIssueAlarm:
          'Potential On-premises Device Misconfigurations Alarm Active',
        potentialSpeakerConfigurationIssueAlarmMessage:
          'InformaCast detected potential on-premises device misconfigurations. These misconfigurations may impact notification delivery. Visit your <0>System Health Dashboard</0> for more information.',
      },
    },
    quickCancel: {
      closeNotifications: 'Close Notifications',
      resend: 'Resend',
      expire: 'Expire',
      activeNotifications: 'Active Notifications',
      pastNotifications: 'Past Notifications',
      viewAll: 'Go to Notification History',
    },
    quickSend: {
      closeSend: 'Close Send',
      failedToLoad:
        'Your message templates have failed to load. Please refresh the page. If problems continue, contact your system administrator.',
      searchPlaceholder: 'Search Message Templates',
      tooltip: 'Send {{messageTemplate}}',
      clearSearch: 'Clear Quick Send Search',
    },
  },
};

export default LayoutLocale;
export const layout_t = registerResource<typeof LayoutLocale.en>('layout', LayoutLocale);
