import { useState } from 'react';
import { Box, Icon, Typography } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import { ConfirmationModalProps } from './ConfirmationModalContainer';
import { CustomRegistryModalContainer } from './CustomRegistryModalContainer';
import { TextField } from './components/TextField';

export const ConfirmationRegistryModal = ({
  id,
  title,
  children,
  hideModal,
  confirmModal,
  confirmTitle,
  confirmCb,
  registryModalProps,
  textConfirmLabel,
  textConfirm,
  isSaving,
  isDestructive,
}: ConfirmationModalProps) => {
  const [userConfirmEntry, setUserConfirmEntry] = useState('');
  return (
    <CustomRegistryModalContainer
      id={id}
      modalProps={registryModalProps}
      title={title}
      isLoading={isSaving}
      secondaryButtonProps={{
        id: 'confirmModalCancel',
        onClick: hideModal,
        label: common_t(['button', 'cancel']),
        startIcon: <Icon.Cancel />,
      }}
      primaryButtonProps={{
        id: 'confirmModalOk',
        onClick: () => confirmModal(confirmCb),
        disabled: !!textConfirm && userConfirmEntry.toLowerCase() !== textConfirm,
        label:
          confirmTitle ||
          (isDestructive ? common_t(['button', 'delete']) : common_t(['button', 'ok'])),
        startIcon: isDestructive ? <Icon.Delete /> : <Icon.Save />,
        color: isDestructive ? 'error' : 'primary',
      }}>
      {/*Children can be ReactNodes or passed as strings.
    When they are strings we want them to be passed as styled text*/}
      {typeof children === 'string' ? <Typography variant="body">{children}</Typography> : children}
      {textConfirm && (
        <Box mt="md" flex={false}>
          <TextField
            id="textConfirm"
            name="textConfirm"
            label={textConfirmLabel || common_t(['delete', 'textConfirm'])}
            xs={12}
            value={userConfirmEntry}
            onChange={event => setUserConfirmEntry((event.target as HTMLInputElement).value)}
            onKeyPress={(event: any) =>
              event.key === 'Enter' &&
              userConfirmEntry.toLowerCase() === textConfirm &&
              confirmModal(confirmCb)
            }
          />
        </Box>
      )}
    </CustomRegistryModalContainer>
  );
};
