import { AxiosError } from 'axios';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import times from 'lodash/times';
import { Component, PropsWithChildren } from 'react';
import ReactSelect, { ActionMeta, InputActionMeta, Props as SelectProps } from 'react-select';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  Box,
  COLORS,
  FormControlWrapper,
  GridContainer,
  GridItem,
  Icon,
  IconButton,
  useBreakpoints,
} from 'singlewire-components';
import { common_t } from '../../../../CommonLocale';
import { ApiRequest, PermissionDeniedError } from '../../../../common-types';
import { DEFAULT_PAGER_LIMIT, GLOBAL_FACILITY_ID } from '../../../../constants';
import { getErrorContainerId } from '../../../../core/forms/FormUtils';
import { SearchAndPaginationOptions } from '../../../../core/search-pager/SearchPagerActions';
import {
  formatCollaborationGroup,
  formatDefault,
  formatDistributionList,
  formatIncidentPlan,
  formatUser,
} from '../../../../core/utils/formatters';
import { getFacilityNameTag } from '../../../features/facilities/FacilityNameTag';
import { aria_t } from '../../ARIALocale';
import { shared_t } from '../../SharedLocale';
import { RemoteSelectProps } from './RemoteSelectContainer';
import { getErrors, parseErrors } from './utils';

interface State {
  // so we can emit and store ids in redux but still deal with option objects locally
  proxiedValue: any | any[] | null;
  results: any[];
  isMenuOpen: boolean;
  inputValue: string;
}

export const getSelectStyles = (error?: boolean) => ({
  control: (base: any, state: any) => {
    const hasError =
      error ||
      (state.selectProps.meta &&
        state.selectProps.meta.error &&
        state.selectProps.meta.submitFailed) ||
      state.error;
    return {
      ...base,
      backgroundColor: state.isDisabled ? COLORS.GRAY_5 : COLORS.WHITE,
      cursor: state.isDisabled ? 'not-allowed' : base.cursor,
      boxShadow: '0',
      minHeight: '30px',
      border: `1px solid ${hasError ? COLORS.RED_40 : COLORS.GRAY_40}`,
      '&:focus-within': {
        border: `1px solid ${COLORS.BLUE_60}`,
        boxShadow: hasError
          ? `inset 0 0 0 1px ${COLORS.RED_40}`
          : `inset 0 0 0 1px ${COLORS.BLUE_60}`,
      },
      '&:hover': {
        border: `1px solid ${COLORS.GRAY_40}`,
        cursor: 'pointer',
      },
    };
  },
  singleValue: (base: any, state: any) => ({
    ...base,
    color: state.isDisabled ? COLORS.GRAY_80 : COLORS.BLACK,
    fontSize: '16px',
  }),
  placeholder: (base: any) => ({
    ...base,
    fontSize: '16px',
    color: COLORS.GRAY_60,
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: COLORS.GRAY_40,
    padding: '6px',
  }),
  menu: (base: any) => ({
    ...base,
    padding: '0',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0',
    zIndex: '5',
    marginTop: '5px',
    '& < div': {
      marginTop: '10px',
    },
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected
      ? COLORS.BLUE_10
      : state.isFocused
        ? COLORS.GRAY_10
        : 'transparent',
    wordBreak: 'break-all',
    color: COLORS.BLACK,
    padding: '0.25rem',
    '&:hover [aria-disabled="false"]': {
      backgroundColor: COLORS.GRAY_10,
      color: COLORS.BLACK,
    },
    '&[aria-disabled="true"]': {
      color: COLORS.GRAY_20,
    },
    pointerEvents: 'all !important',
  }),
  multiValueLabel: (base: any, state: any) => ({
    ...base,
    paddingRight: '6px',
    color: COLORS.WHITE,
  }),
  multiValue: (base: any, state: any) => ({
    ...base,
    opacity: 1,
    backgroundColor: COLORS.BLUE_60,
  }),
  multiValueRemove: (base: any, state: any) => ({
    ...base,
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_60,
    display: state.isDisabled ? 'none' : base.display,
    '&:hover': {
      backgroundColor: COLORS.GRAY_40,
      color: COLORS.WHITE,
    },
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    marginTop: '0px',
    paddingTop: 0,
    paddingBottom: 0,
    margin: state.selectProps.isCreatable ? '0.25rem' : 0,
    overflow: 'hidden',
    color: 'white',
  }),
  container: (base: any) => ({
    ...base,
    color: 'white',
    flex: 1,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: COLORS.GRAY_40,
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '30px',
  }),
});

export const getPagerId = (name: string) => `remote-select-${name}`;

const CustomReactSelect = (props: Omit<SelectProps, 'isClearable'>) => {
  return (
    <ReactSelect
      {...props}
      // The boilerplate react-select clear button had poor accessibility.
      // Prohibiting isClearable so we can override it.
      isClearable={false}
    />
  );
};

export class RemoteSelect extends Component<RemoteSelectProps, State> {
  pagerId = getPagerId(this.props.input!.name);
  placeholder: string;
  labelFn: (item: any) => string;
  apiRequest: ApiRequest;
  responseDataFn?: (data: any[]) => any[];
  search$ = new Subject<string>();
  searchSubscription: Subscription;
  state: State = {
    proxiedValue: null,
    results: [],
    isMenuOpen: false,
    inputValue: '',
  };
  requestOptions?: SearchAndPaginationOptions;

  constructor(props: RemoteSelectProps) {
    super(props);

    // For certain common resources, only need the paginationType to properly set up the RemoteSelect
    if (this.props.paginationType === 'distributionLists') {
      this.apiRequest = [
        'distributionLists',
        [],
        'GET',
        {
          params: {
            facilityId:
              props.facilitiesEnabled &&
              props.actingFacilityId === GLOBAL_FACILITY_ID &&
              props.globalOnly
                ? GLOBAL_FACILITY_ID
                : undefined,
          },
        },
      ];
      this.labelFn = formatDistributionList;
    } else if (this.props.paginationType === 'messageTemplates') {
      this.apiRequest = [
        'messageTemplates',
        [],
        'GET',
        {
          params: {
            q: 'follow-up:false',
            facilityId:
              props.facilitiesEnabled && props.actingFacilityId === GLOBAL_FACILITY_ID
                ? GLOBAL_FACILITY_ID
                : undefined,
          },
        },
      ];
      this.labelFn = formatDefault;
    } else if (this.props.paginationType === 'users') {
      this.apiRequest = ['users', [], 'GET', { params: { type: 'regular' } }];
      this.placeholder = common_t(['resource', 'users']);
      this.labelFn = formatUser;
    } else if (this.props.paginationType === 'deviceGroups') {
      this.apiRequest = [
        'deviceGroups',
        [],
        'GET',
        {
          params: {
            facilityId:
              props.facilitiesEnabled &&
              props.actingFacilityId === GLOBAL_FACILITY_ID &&
              props.globalOnly
                ? GLOBAL_FACILITY_ID
                : undefined,
          },
        },
      ];
      this.labelFn = formatDefault;
    } else if (this.props.paginationType === 'collaborationGroups') {
      this.apiRequest = [
        'collaborationGroups',
        [],
        'GET',
        {
          params: {
            facilityId:
              props.facilitiesEnabled &&
              props.actingFacilityId === GLOBAL_FACILITY_ID &&
              props.globalOnly
                ? GLOBAL_FACILITY_ID
                : undefined,
          },
        },
      ];
      this.labelFn = formatCollaborationGroup;
    } else if (this.props.paginationType === 'areasOfInterest') {
      this.apiRequest = ['areasOfInterest', [], 'GET'];
      this.labelFn = formatDefault;
    } else if (this.props.paginationType === 'confirmationRequests') {
      this.apiRequest = [
        'confirmationRequests',
        [],
        'GET',
        {
          params: {
            facilityId:
              props.facilitiesEnabled && props.actingFacilityId === GLOBAL_FACILITY_ID
                ? GLOBAL_FACILITY_ID
                : undefined,
          },
        },
      ];
      this.labelFn = formatDefault;
    } else if (this.props.paginationType === 'incidentPlans') {
      this.apiRequest = [
        'incidentPlans',
        [],
        'GET',
        {
          params: {
            facilityId:
              props.facilitiesEnabled && props.actingFacilityId === GLOBAL_FACILITY_ID
                ? GLOBAL_FACILITY_ID
                : undefined,
          },
        },
      ];
      this.labelFn = formatIncidentPlan;
    } else if (this.props.paginationType === 'fusionExtensionEndpoints') {
      this.apiRequest = ['fusionExtensionEndpoints', [], 'GET'];
      this.labelFn = formatDefault;
    } else if (this.props.paginationType) {
      throw new Error(`Unknown type supplied ${this.props.paginationType}`);
    }

    this.requestOptions = this.props.requestOptions || this.requestOptions;
    this.apiRequest = this.props.apiRequest || this!.apiRequest;
    this.responseDataFn = this.props.responseDataFn || this.responseDataFn;
    this.labelFn = this.props.labelFn || this!.labelFn || formatDefault;
    this.placeholder = this.props.placeholder || this!.placeholder || common_t(['label', 'select']);

    if (!this.apiRequest) {
      throw new Error('Must specify an apiRequest, a known type, or a pager.');
    }

    this.searchSubscription = this.search$.pipe(debounceTime(300)).subscribe(() => {
      this.setState({ results: [] }, () => {
        const [, , , opts] = this.apiRequest;
        const baseQuery = opts && opts.params && opts.params.q;
        this.props.searchPagerChangeQuery(
          this.pagerId,
          baseQuery ? { and: [baseQuery, this.state.inputValue] } : this.state.inputValue
        );
      });
    });
  }

  getBackupItems(): any[] {
    return (
      (this.props.initialItems as any[] | undefined) ||
      (this.props.secondaryModelValue
        ? ((this.props.isMulti
            ? this.props.secondaryModelValue
            : this.props.secondaryModelValue
              ? [this.props.secondaryModelValue]
              : undefined) as any[] | undefined)
        : undefined) ||
      []
    );
  }

  componentDidUpdate(prevProps: Readonly<RemoteSelectProps>) {
    const { pager } = this.props;
    this.labelFn = this.props.labelFn || this!.labelFn || formatDefault;

    if (this.props.apiRequest) {
      this.apiRequest = this.props.apiRequest;
    }

    // In the case of a pager error, show either a growl or if a 401 show
    if (pager && pager.error) {
      const pagerError = pager.error as AxiosError | PermissionDeniedError;
      if (
        pagerError instanceof PermissionDeniedError ||
        (pagerError.response && pagerError.response!.status === 401)
      ) {
        // If we don't have permissions, show the secondary model or initial items
        this.setState({ results: this.getBackupItems() });
      } else {
        // If we had a different failure, show a growl message
        this.props.growl(shared_t(['pager', 'failedSearch']));
      }
      this.props.searchPagerDestroy(this.pagerId);
    } else {
      // Compare our previous pager data to our new pager data. If it is different, update and append our results
      const previousData = prevProps.pager && prevProps.pager.data && prevProps.pager.data.data;
      const currentData = pager && pager.data && pager.data.data;
      if (currentData && currentData !== previousData) {
        // HACK, if the remote data contains something like {options: ["Yes", "No"]},
        // React Select will try to use those, in this case just remove that key
        const formattedNewData = currentData.map(({ options, ...rest }) => rest);
        const results = [...this.state.results, ...formattedNewData];

        if (this.props.isMulti) {
          // Remove already selected values from the available options
          const notSelectedResults = results.filter(
            option => !this.props.input!.value.includes(this.getOptionValue(option))
          );
          // If we're not currently search and we've got a "pageable" pager
          // and we've got fewer than 10 items
          if (
            pager &&
            !pager.isSearching &&
            pager.data &&
            pager.data.next &&
            notSelectedResults.length < DEFAULT_PAGER_LIMIT
          ) {
            // Fetch more items
            this.props.searchPagerNextPage(this.pagerId);
          }
        }

        return this.setState({ results: [...(this.props.additionalItems || []), ...results] });
      }
    }

    // handle case where select value changes from one resource ID to another
    // without opening the menu (e.g. clicking "cancel" button) and thus
    // does not call the <Select/>'s onChange prop
    if (!pager && !isEqual(this.props.secondaryModelValue, prevProps.secondaryModelValue)) {
      this.handleChange(this.props.secondaryModelValue);
    }
  }

  componentWillUnmount() {
    this.props.searchPagerDestroy(this.pagerId);
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  getLoadingMessage = () => common_t(['label', 'loading']);

  handleChange = (newValue: any, action?: ActionMeta<any>) => {
    const isMulti = this.props.isMulti!;
    const onChange = this.props.input!.onChange;
    // workaround for https://github.com/JedWatson/react-select/issues/2804
    const value = !isMulti && Array.isArray(newValue) && newValue.length === 0 ? null : newValue;

    if (this.props.secondaryModel && this.props.meta) {
      this.props.changeSecondaryModel(this.props.meta.form, this.props.secondaryModel, value);
    }

    this.setState({ proxiedValue: value }, () => {
      // update redux store with value
      if (!value) {
        onChange(value);
      } else if (isMulti) {
        if (Array.isArray(value)) {
          onChange(value.map(val => this.getOptionValue(val)));
        } else {
          onChange([]);
        }
      } else {
        onChange(this.getOptionValue(value));
      }

      if (this.props.secondaryModelChanged) {
        this.props.secondaryModelChanged(value, action);
      }
    });
  };

  getOptionValue = (option: any) => option[this.props.valueKey || 'id'];

  findOrCreateOption = (value: any): any => {
    const proxiedValue = this.state.proxiedValue;
    // prettier-ignore
    const options = (proxiedValue ?
                      (Array.isArray(proxiedValue) ? proxiedValue : [proxiedValue]) :
                      undefined) ||
                    this.getBackupItems();

    // No value = no option to display
    // value !== 0 is needed because some resources can have an id of zero (e.x. LPI SIP Server Groups)
    if (!value && value !== 0) {
      return null;
    }

    const option = options.find(o => this.getOptionValue(o) === value);

    if (option) {
      // We found an option that corresponds to the current value
      return option;
    } else {
      // We create a an option where the label and the value are the same
      return { name: value, [this.props.valueKey || 'id']: value };
    }
  };

  getValue = () => {
    this.labelFn = this.props.labelFn || this!.labelFn || formatDefault;
    const isMulti = this.props.isMulti!;
    const value = this.props.input!.value;

    if (!isMulti) {
      return this.findOrCreateOption(value);
    }

    return Array.isArray(value) ? value.map(val => this.findOrCreateOption(val)) : value;
  };

  // don't want to change the input value during onBlur
  onBlur = () => this.props.input!.onBlur(undefined);

  loadOptions = (inputValue: string, actionMeta: InputActionMeta) => {
    this.setState({ inputValue }, () => {
      if (actionMeta.action === 'input-change') {
        this.search$.next(inputValue);
      }
    });
  };

  // generate 40 blank dummy options
  buildDummyItems = () =>
    times(20).map(() => ({
      name: '',
      id: '__DUMMY_ID__',
    }));

  onMenuOpen = () => {
    // It is fired for some reason on every keystroke. Ignore it if it is already opened. This
    // appears to be resolved in newer versions of react-select
    if (this.state.isMenuOpen) {
      return;
    }
    // HACK: This gets around scroll not working when the results aren't initially scrollable. We can remove this
    // once this PR is merged: https://github.com/JedWatson/react-select/pull/2861
    this.setState({ isMenuOpen: true, results: this.buildDummyItems() }, () => {
      this.setState({ isMenuOpen: true, results: [] }, () => {
        const [url, urlParams, method, opts = {}] = this.apiRequest;
        const updatedApiRequest: ApiRequest = [
          url,
          urlParams,
          method,
          { ...opts, params: { ...(opts.params || {}), limit: DEFAULT_PAGER_LIMIT } },
        ];

        this.props.searchPagerCreate(
          this.pagerId,
          updatedApiRequest,
          this.props.pathname,
          this.requestOptions,
          this.responseDataFn
        );
      });
    });
  };

  onMenuScrollToBottom = () => {
    this.props.searchPagerNextPage(this.pagerId);
  };
  onKeyDown = () => {
    this.props.searchPagerNextPage(this.pagerId);
  };

  onMenuClose = () => {
    this.setState({ isMenuOpen: false }, () => {
      this.props.searchPagerDestroy(this.pagerId);
    });
  };

  render() {
    const {
      id,
      input,
      meta,
      pager,
      isMulti,
      isDisabled,
      className,
      isClearable,
      label,
      theme,
      actions,
      xs,
      sm,
      md,
      lg,
      xl,
      menuPortalTarget = null,
      facilitiesEnabled,
      actingFacilityId,
      userFacilities,
      ...rest
    } = this.props;
    const errors = getErrors({ meta });
    const errorContainerId = getErrorContainerId(meta!.form, input!.name);

    return (
      <GridContainer>
        <GridItem xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          <FormControlWrapper
            label={label}
            error={meta?.error && meta?.submitFailed}
            helperText={!isEmpty(errors) ? parseErrors(errors) : (rest as any).helperText}
            helperTextId={errorContainerId}
            helperTextClassName="field-errors-container"
            inputId={id || 'remote-select-input'}
            helperActions={
              <Actions
                id={id}
                label={label}
                isClearable={isClearable || isMulti}
                onClear={() => this.handleChange([])}
                size="xs"
                disabled={!!isDisabled}>
                {actions}
              </Actions>
            }>
            <Box>
              <CustomReactSelect
                {...rest}
                // we need meta in the styles
                {...{ meta }}
                instanceId={id}
                placeholder=""
                onMenuOpen={this.onMenuOpen}
                onMenuScrollToBottom={this.onMenuScrollToBottom}
                onMenuClose={this.onMenuClose}
                onInputChange={this.loadOptions}
                menuIsOpen={this.state.isMenuOpen}
                inputValue={this.state.inputValue || ''}
                aria-label={label}
                options={this.state.results}
                onBlur={this.onBlur}
                isLoading={pager ? pager.isSearching : false}
                value={this.getValue()}
                onChange={this.handleChange}
                getOptionLabel={
                  facilitiesEnabled
                    ? (resource: any) =>
                        this.labelFn(resource) +
                        getFacilityNameTag(
                          facilitiesEnabled,
                          actingFacilityId,
                          userFacilities,
                          resource
                        )
                    : this.labelFn
                }
                getOptionValue={this.getOptionValue}
                loadingMessage={this.getLoadingMessage}
                classNamePrefix="singlewire-select"
                styles={getSelectStyles()}
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                isDisabled={isDisabled}
                className={classNames(className)}
                menuPortalTarget={menuPortalTarget}
                aria-errormessage={errorContainerId}
                aria-live="polite"
                ariaLiveMessages={!isEmpty(errors) ? (parseErrors(errors) as any) : undefined}
                aria-describedby={errorContainerId}
              />
              <Box ml={!!actions || isClearable ? 'sm' : 'none'}>
                <Actions
                  id={id}
                  label={label}
                  isClearable={isClearable || isMulti}
                  onClear={() => this.handleChange([])}
                  size="md"
                  disabled={!!isDisabled}>
                  {actions}
                </Actions>
              </Box>
            </Box>
          </FormControlWrapper>
        </GridItem>
      </GridContainer>
    );
  }
}

const Actions = ({
  isClearable,
  label,
  id,
  onClear,
  children,
  size,
  disabled,
}: PropsWithChildren<{
  isClearable?: boolean;
  label: string;
  id?: string;
  onClear: () => void;
  size?: 'xs' | 'md';
  disabled: boolean;
}>) => {
  const breakpoint = useBreakpoints();

  if (disabled) {
    return null;
  }

  if (size === 'xs' && ['xs', 'sm'].includes(breakpoint)) {
    return (
      <Box>
        {isClearable && (
          <IconButton
            id={id + '-remove'}
            icon={<Icon.Cancel />}
            label={aria_t(['button', 'clear'], { name: label })}
            onClick={onClear}
          />
        )}
        {children}
      </Box>
    );
  }

  if (size === 'md' && ['md', 'lg', 'xl'].includes(breakpoint)) {
    return (
      <>
        {isClearable && (
          <IconButton
            id={id + '-remove'}
            icon={<Icon.Cancel />}
            label={aria_t(['button', 'clear'], { name: label })}
            onClick={onClear}
          />
        )}
        {children}
      </>
    );
  }

  return null;
};
