import classNames from 'classnames';
import { createRef, PureComponent, RefObject } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  COLORS,
  HoverCard,
  Icon,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../CommonLocale';
import { GLOBAL_FACILITY_ID } from '../../constants';
import { RightSidebarType } from '../../core/layout/LayoutActions';
import Routes from '../../core/route/Routes';
import { getPagerData } from '../../core/search-pager/SearchPagerReducer';
import { MobileApiMessageTemplate } from '../../mobile-api-types';
import { SearchPagerContainer } from '../shared/components/TableContainer';
import { FlexPageControls } from '../shared/SearchPager/FlexPageControls';
import { SearchPaginationControlsProps } from '../shared/SearchPager/SearchPaginationControls';
import { shared_t } from '../shared/SharedLocale';
import { layout_t } from './LayoutLocale';
import { QuickSendProps } from './QuickSendContainer';

export const PAGER_ID = 'list-message-templates-quick-send';
export const GLOBAL_PAGER_ID = 'list-global-message-templates-quick-send';

interface QuickSendListItemProps {
  messageTemplate: MobileApiMessageTemplate;
  toggleRightSidebarType: () => void;
}

const Header = styled.div`
  height: 2.5rem;
  padding: 0 0.5rem;
`;

const QuickSendClose = styled(IconButton)`
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
`;

const StyledRow = styled(TableRow)`
  border-radius: 0.25rem;

  &:hover {
    background-color: ${COLORS.GRAY_5};
  }

  &:focus-within {
    background-color: ${COLORS.GRAY_5};
    outline: 2px solid ${COLORS.WHITE};
    box-shadow: inset 0 0 0 2px ${COLORS.BLUE_60};
  }
`;

const StyledCell = styled(TableCell)`
  border-radius: 0.25rem;
  padding: 0;
`;

const Subtitle = styled.div`
  color: ${COLORS.GRAY_80};
  font-size: 0.875rem;
  height: 2.5rem;
  background-color: ${COLORS.GRAY_5};
  padding: 0.5rem;
`;

export const QuickSendListItem = ({
  messageTemplate,
  toggleRightSidebarType,
}: QuickSendListItemProps) => {
  const shouldShowAttachments =
    messageTemplate.image > 0 ||
    messageTemplate.audio > 0 ||
    !!messageTemplate.confirmationRequestId ||
    !!messageTemplate.incidentPlanId;

  return (
    <StyledRow border={false}>
      <StyledCell>
        <HoverCard
          id={`hover-card-${messageTemplate.id}`}
          side="left"
          openDelay={0}
          trigger={
            <Link
              to={`${Routes.Notifications.Send}?messageTemplateId=${messageTemplate.id}`}
              onClick={toggleRightSidebarType}>
              <Box flex={false} m="sm">
                <Typography variant="body">
                  {messageTemplate.name!.length > 32
                    ? `${messageTemplate.name?.substring(0, 32)}...`
                    : messageTemplate.name}
                </Typography>

                <Typography variant="helptext">
                  {messageTemplate.subject!.length > 32
                    ? `${messageTemplate.subject?.substring(0, 32)}...`
                    : messageTemplate.subject}
                </Typography>
                <Box mt="xs">
                  {messageTemplate.image > 0 && (
                    <Box mr="xs">
                      <Tooltip title={common_t(['label', 'image'])}>
                        <Badge variant="outline">
                          <Icon.Image size="sm" />
                        </Badge>
                      </Tooltip>
                    </Box>
                  )}
                  {messageTemplate.audio > 0 && (
                    <Box mr="xs">
                      <Tooltip title={common_t(['label', 'audio'])}>
                        <Badge variant="outline">
                          <Icon.Microphone size="sm" />
                        </Badge>
                      </Tooltip>
                    </Box>
                  )}
                  {!!messageTemplate.confirmationRequestId && (
                    <Box mr="xs">
                      <Tooltip title={common_t(['resource', 'confirmationRequest'])}>
                        <Badge variant="outline">
                          <Icon.ConfirmationRequest size="sm" />
                        </Badge>
                      </Tooltip>
                    </Box>
                  )}
                  {!!messageTemplate.incidentPlanId && (
                    <Box mr="xs">
                      <Tooltip title={common_t(['resource', 'incidentPlan'])}>
                        <Badge variant="outline">
                          <Icon.IncidentPlan size="sm" />
                        </Badge>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
            </Link>
          }>
          <Typography variant="body">{messageTemplate.name}</Typography>
          <Box mt="sm" direction="column">
            <Typography variant="body">{common_t(['label', 'subject'])}</Typography>
            <Typography variant="subtitle">
              {messageTemplate.subject!.length > 400
                ? `${messageTemplate.subject?.substring(0, 400)}...`
                : messageTemplate.subject}
            </Typography>
          </Box>

          {messageTemplate.body && (
            <Box mt="sm" direction="column">
              <Typography variant="body">{common_t(['label', 'body'])}</Typography>
              <Typography variant="subtitle">
                {messageTemplate.body!.length > 400
                  ? `${messageTemplate.body?.substring(0, 400)}...`
                  : messageTemplate.body}
              </Typography>
            </Box>
          )}

          {shouldShowAttachments && (
            <Box mt="sm" direction="column">
              <Typography variant="body">{common_t(['label', 'attachments'])}</Typography>
              <Box mt="xs" flexWrap="wrap" spacing={2}>
                {messageTemplate.image > 0 && (
                  <Badge variant="outline">
                    <Icon.Image size="sm" />
                    {common_t(['label', 'image'])}
                  </Badge>
                )}
                {messageTemplate.audio > 0 && (
                  <Badge variant="outline">
                    <Icon.Microphone size="sm" />
                    {common_t(['label', 'audio'])}
                  </Badge>
                )}
                {!!messageTemplate.confirmationRequestId && (
                  <Badge variant="outline">
                    <Icon.ConfirmationRequest size="sm" />
                    {common_t(['resource', 'confirmationRequest'])}
                  </Badge>
                )}
                {!!messageTemplate.incidentPlanId && (
                  <Badge variant="outline">
                    <Icon.IncidentPlan size="sm" />
                    {common_t(['resource', 'incidentPlan'])}
                  </Badge>
                )}
              </Box>
            </Box>
          )}
        </HoverCard>
      </StyledCell>
    </StyledRow>
  );
};

export class CustomControls extends PureComponent<SearchPaginationControlsProps> {
  getComputedHeight = (elem: HTMLElement) =>
    parseFloat((window as any).getComputedStyle(elem, null).getPropertyValue('height'));

  getVanillaHeight = (elem: HTMLElement) =>
    this.getComputedHeight(elem) -
    parseFloat((window as any).getComputedStyle(elem, null).getPropertyValue('padding-top')) -
    parseFloat((window as any).getComputedStyle(elem, null).getPropertyValue('padding-bottom'));

  componentDidMount() {
    // Measure page and put in that many list items.
    const header = (window as any).document.getElementById('quick-send-header');
    const subtitle = (window as any).document.getElementById('quick-send-subtitle');
    const controlsNode = (window as any).document.getElementById('quick-send-nav-row');
    const containerNode = (window as any).document.getElementById('quick-send');

    const searchBarHeight = 100;
    const headerHeight = this.getComputedHeight(header);
    const subtitleHeight = this.getComputedHeight(subtitle);
    const listItemHeight = 64;
    const controlsHeight = this.getComputedHeight(controlsNode);
    const containerHeight = this.getVanillaHeight(containerNode);

    const itemLimit = Math.floor(
      (containerHeight - (controlsHeight + searchBarHeight + headerHeight + subtitleHeight)) /
        listItemHeight
    );

    this.props.onLimitChanged(Math.max(itemLimit, 1), { keepGlobalLimit: true });
  }

  render() {
    return (
      <TableRow border={false}>
        <Box id="quick-send-nav-row" flex={false}>
          <FlexPageControls {...this.props} />
        </Box>
      </TableRow>
    );
  }
}

export interface SearchBarComponentProps {
  onSearchQueryChanged: (q: string) => void;
  searchInputRef: RefObject<HTMLInputElement>;
  defaultValue?: string;
  maxSearchLength?: number;
}

export class QuickSend extends PureComponent<QuickSendProps> {
  private readonly searchInputRef: RefObject<HTMLInputElement> = createRef();

  componentDidUpdate(prevProps: Readonly<QuickSendProps>) {
    if (
      this.props.rightSidebarOpened &&
      this.props.rightSidebarType === RightSidebarType.SEND &&
      prevProps.rightSidebarType !== RightSidebarType.SEND
    ) {
      this.props.searchPagerRefresh(
        this.props.facilitiesEnabled && this.props.actingFacilityId === GLOBAL_FACILITY_ID
          ? GLOBAL_PAGER_ID
          : PAGER_ID
      );
      if (this.searchInputRef.current) {
        this.searchInputRef.current.focus();
      }
    } else if (
      this.props.rightSidebarOpened &&
      this.props.facilitiesEnabled &&
      this.props.actingFacilityId !== prevProps.actingFacilityId
    ) {
      this.props.searchPagerRefresh(
        this.props.facilitiesEnabled && this.props.actingFacilityId === GLOBAL_FACILITY_ID
          ? GLOBAL_PAGER_ID
          : PAGER_ID
      );
    }
  }

  render() {
    const {
      rightSidebarOpened,
      rightSidebarType,
      toggleRightSidebarType,
      globalPager,
      pager,
      facilitiesEnabled,
      actingFacilityId,
    } = this.props;

    const useGlobalPager = facilitiesEnabled && actingFacilityId === GLOBAL_FACILITY_ID;

    return (
      <>
        {rightSidebarType === RightSidebarType.SEND && (
          <aside
            id="quick-send"
            className={classNames('sidebar c-overflow', { toggled: rightSidebarOpened })}>
            <div className="listview" id="quickSendItemList">
              <Header id="quick-send-header">
                <Box align="between">
                  <Typography variant="h3">{common_t(['label', 'send'])}</Typography>
                  <QuickSendClose
                    id="close-quick-send"
                    label={layout_t(['quickSend', 'closeSend'])}
                    color="gray"
                    icon={<Icon.Cancel />}
                    onClick={() => toggleRightSidebarType(RightSidebarType.NONE)}
                  />
                </Box>
              </Header>
              <Subtitle id="quick-send-subtitle">
                {common_t(['resource', 'messageTemplates'])}
              </Subtitle>
              <Box m="md">
                <SearchPagerContainer
                  pagerId={useGlobalPager ? GLOBAL_PAGER_ID : PAGER_ID}
                  apiRequest={[
                    'messageTemplates',
                    [],
                    'GET',
                    {
                      params: {
                        q: 'follow-up:false',
                        'include-notification-profile': true,
                        ...(useGlobalPager ? { facilityId: actingFacilityId } : {}),
                      },
                    },
                  ]}
                  customControls={(props: SearchPaginationControlsProps) => (
                    <CustomControls {...props} />
                  )}
                  options={{
                    noResultsText: shared_t(['pager', 'noResults'], {
                      resource: common_t(['resource', 'messageTemplatesLower']),
                    }),
                    noSearchResultsText: shared_t(['pager', 'noSearchResults'], {
                      resource: common_t(['resource', 'messageTemplatesLower']),
                    }),
                    isLazy: true,
                    searchGridItemSize: 12,
                  }}>
                  {getPagerData<MobileApiMessageTemplate>(useGlobalPager ? globalPager : pager).map(
                    template => (
                      <QuickSendListItem
                        key={template.id}
                        messageTemplate={template}
                        toggleRightSidebarType={() => toggleRightSidebarType(RightSidebarType.NONE)}
                      />
                    )
                  )}
                </SearchPagerContainer>
              </Box>
            </div>
          </aside>
        )}
      </>
    );
  }
}
